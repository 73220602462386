
import Vue_ from "vue";
// eslint-disable-next-line no-unused-vars
import { VueConstructor } from "vue/types/umd";
const Vue = Vue_ as VueConstructor<Vue_ & { spaParent: ISpaParent }>;

export default Vue.extend({
  name: "AssignmentGroupEditor",
  props: {
    camera: Object as () => Camera,
    assignmentGroup: Object as () => IAssignmentGroup,
    toggleAssignmentGroupEditor: Function,
  },
  data() {
    return {
      unmount: () => {},
    };
  },
  inject: ["spaParent"],
  methods: {
    async mountPlayer() {
      const ref = this.$refs[`AssignmentEditor`];
      const player = this.spaParent.areas.find(
        (e: any) => e.name === "AssignmentEditor"
      );
      if (player) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          player.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                entityId: this.camera.id,
                entityType: "Camera",
                entityTitle: this.camera.title,
                assignmentGroupId: this.assignmentGroup?.id,
                onClose: (id: string) => {
                  this.toggleAssignmentGroupEditor(false, id);
                },
              },
            };
          })
        );
      }
    },
  },
  mounted() {
    this.mountPlayer();
  },
  destroyed() {
    this.unmount();
  },
});
