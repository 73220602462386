
import Vue from "vue";
import CameraPreview from "./components/CameraPreview.vue";
import { repositoryGetter, TUnionRepo } from "@netvision/lib-api-repo";

export default Vue.extend({
  name: "App",
  components: {
    CameraPreview,
  },
  data() {
    const props = this.$parent?.props;
    const { options } = props;
    return {
      options: {
        camera: props?.camera || {
          id: "",
          title: "",
          subTitle: "",
          archiveLink: "",
          address: "",
          icon: "",
          iconBgColor: "",
          dvrSettings: null,
          archiveId: "",
        },
        blockOnMatch: props?.blockOnMatch || false,
        isCompactView: options?.isCompactView || false,
        compactViewSubscriptor: options?.compactViewSubscriptor,
        isMapView: options?.isMapView || (false as Boolean),
        toast: options?.toast,
        assignedAnalytics: props?.assignedAnalytics
          ? [...props?.assignedAnalytics]
          : [],
        permissionScopes: options?.permissionScopes || new Map(),
        assignmentTypes: options?.assignmentTypes || [],
        assignmentGroups: options?.assignmentGroups,
        analytics: options?.analytics,
        actions: props?.actions || [
          {
            name: "archive",
          },
        ],
        mode: options?.mode,
      } as CameraOptions,
      $api: {},
    };
  },
  beforeMount() {
    this.$api = repositoryGetter(this.$parent?.props?.lib?.name) as TUnionRepo;
  },
  provide(): any {
    return {
      spaParent: this.$parent,
    };
  },
});
