import "./set-public-path";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { RUSSIAN_TRANSLATIONS } from "./translations/ru";
import singleSpaVue from "single-spa-vue";
import Tooltip from "primevue/tooltip";
import VueRouter from "vue-router";
import Button from "primevue/button";
Vue.component("Button", Button);
import OverlayPanel from "primevue/overlaypanel";
Vue.component("OverlayPanel", OverlayPanel);
import Toast from "primevue/toast";
Vue.component("Toast", Toast);
import ToastService from "primevue/toastservice";
Vue.use(ToastService);
import ScrollPanel from "primevue/scrollpanel";
Vue.component("ScrollPanel", ScrollPanel);

import App from "./App.vue";

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.directive("tooltip", Tooltip);
Vue.config.productionTip = false;

const routes = [] as any;
const router = new VueRouter({
  base: __dirname,
  mode: "history",
  routes,
});

const locale = "ru";
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
};
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    render: (h: any) => h(App),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
export const update = vueLifecycles.update;
