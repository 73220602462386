export const RUSSIAN_TRANSLATIONS = {
  button: {
    archive: "Архив",
    events: "События",
  },
  message: {
    addAssignment: "Добавить аналитику",
    addAssignmentGroup: "Добавить группу аналитик",
  },
  Rejected: "Отказано",
  Stopped: "Остановлена",
  NotStarted: "Не запущена",
  Starting: "Запускается",
  Stopping: "Останавливается",
  Started: "Запущена",
  status: "Статус",
  unknown: "Неизвестен",
  address: "Адрес",
  category: "Тип",
  back: "Назад",
  coordinatesCopied: "Координаты скопированы",
  blockedBy: "Заблокировал",
  blockedAt: "Время блокировки",
};
